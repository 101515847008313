import React, { useContext, useEffect, useState } from 'react';
import logo from '../../images/wisetek_owl.png'
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { AuthContext } from '../../contexts/AuthContext';
import { Redirect } from 'react-router-dom';
import VarifyEmail from './VerifyEmail';
import { Snackbar, Card, FormControlLabel, Checkbox } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Footer from '../Footer';
import Loading from './Loading';
import { DataContext } from '../../contexts/DataContext';
import SnackbarComponent from '../customComponents/SnackbarComponent';
import moment from 'moment';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: '#f0f5f4'
    },
    paper: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    card: {
        padding: '24px',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn(props) {
    // console.log('props', props)
    const classes = useStyles();
    const [state, setState] = useState({
        email: '',
        password: '',
        authError: '',
    });
    const { signIn, isAuthenticated, emailVerified, loading, email, roles } = useContext(AuthContext);

    const [snackbar, setSnackbar] = useState({
        open: false,
        severity: 'info',
        message: ''
    })

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        // gat all info
        console.log('Login Submission for: ', state.email)
        // let redirectURL = window.location.href
        // console.log('redirect to here: ', redirectURL)
        // Redirect back to the previous URL or the home page
        // const from = (history.location.state && history.location.state.from) || '/home';
        // return <Redirect to={from} />;

        // Login user
        signIn(state)
            .then(resp => {
                console.log(resp);
            })
            .catch(error => {
                console.log(error);
                setState({
                    ...state,
                    authError: error
                })
                setSnackbar({
                    open: true,
                    severity: 'error',
                    message: error
                })
            })
        document.getElementById('login-form').reset();
    }



    if (loading) {
        return (<Loading />)
    }
    else {
        if (isAuthenticated) {
            if (emailVerified) {

                switch (email.toLowerCase()) {
                    case 'roombookings@wisetek.net':
                        return <Redirect to='/roomBookings' />

                    case 'attendanceclock@noemail.wisetek.net':
                        return <Redirect to='/attendanceClock' />

                    case 'wiseacademy@wisetek.net':
                        return <Redirect to='/wiseAcademy' />


                    default:
                        return <Redirect to='/home' />

                }
            }
            else {
                return (
                    <div className={classes.root}>
                        <VarifyEmail />
                    </div>
                )
            }

        } else {
            return (
                <div className={classes.root}>
                    {moment().isSameOrAfter(moment('2026 01 01')) ? <Card style={{ margin: 300 }}><div style={{ textAlign: 'center', margin: 50 }}><div>
                        <img id="logo" src={logo} alt="Logo" className='SpinLogo' />
                    </div><Typography component="h1" variant="h5">Website unavailable</Typography></div></Card> : <Container component="main" maxWidth="xs">
                        <CssBaseline />

                        <div className={classes.paper}>
                            <div>
                                <img id="logo" src={logo} alt="Logo" />
                            </div>
                            <Typography component="h1" variant="h5">
                                Sign in
                            </Typography>
                        </div>
                        <Card className={classes.card}>
                            <form id="login-form" className={classes.form} onSubmit={handleSubmit}>
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 80 }}
                                />
                                <TextField
                                    size="small"
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 80 }}
                                />
                                {/* <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                /> */}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    Sign In
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/forgotpassword" variant="body2">
                                            Forgot password?
                                        </Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/signup" variant="body2">
                                            {"Don't have an account? Sign Up"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>

                            <SnackbarComponent snackbar={snackbar} setSnackbar={setSnackbar} />
                        </Card>
                    </Container>}

                    <Footer />
                </div>
            );
        }
    }
}